@tailwind base;
@tailwind components;
@tailwind utilities;

.background-image {
  background-image: url('./assets/GalleryBg_Mobile.png');
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.modal-background-image {
  background-image: url('./assets/ModalBg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
}

.smMsg {
  font-family: 'Helvetica', 'Arial', sans-serif;
}

br {
  content: '';
  margin: 1em 0;
  display: block;
}

.filter {
  display: flex;
  flex-direction: column;
}
.filter:hover {
  color: theme('colors.accent.light');
}
.filterTop {
  font-size: 0.75rem;
  line-height: 1;
  padding-left: 0.25rem;
}
.filterBottom {
  font-size: 1.5rem;
  line-height: 1;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #ffd000 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

.sharp-fold {
  position: relative;
  background: linear-gradient(-150deg, transparent 1.5em, #feff9c 0);
  border-radius: 0.5em;
}

.sharp-fold::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  background: linear-gradient(
      to left bottom,
      transparent 50%,
      rgba(0, 0, 0, 0.2) 0,
      rgba(0, 0, 0, 0.4)
    )
    no-repeat 100% 0;
  width: 1.73em;
  height: 3em;
  transform: translateY(-1.3em) rotate(-30deg);
  transform-origin: bottom right;
  border-bottom-left-radius: inherit;
  box-shadow: -0.2em 0.2em 0.3em -0.1em rgba(0, 0, 0, 0.15);
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (min-width: 768px) {
  .background-image {
    background-image: url('./assets/GalleryBg_PC.png');
  }
}
